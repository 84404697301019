<template>
	<div>
		<a-spin :spinning="loading">
			<div class="ft20 cl-black cl-main ftw500">微信支付管理</div>
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">
					<div>
						 <a-alert message="如果本身有小程序支付的可以直接选择直连商户支付然后进行保存,如果不懂如何开通支付可以邀请客服协助开通" banner />
					</div>
					
					<div class="mt40">
						<div class="flex alcenter">
							<div class="app-pay-manage-item">
								<div class="ft24 ftw500 cl-black text-center">直连商户支付</div>
								<div class="ft12 ftw400 cl-info text-center mt8">我以前开通过微信支付</div>
								<div class="flex center mt16" v-if="is_setting==1 && type==1">
									<a-button type="primary" disabled>设置</a-button>
								</div>
								<div class="flex center mt16" v-else>
									<a-button type="primary" @click="merchantSettingAct()">设置</a-button>
								</div>
							</div>
							<div class="app-pay-manage-item ml40">
								<div class="ft24 ftw500 cl-black text-center">服务商支付</div>
								<div class="ft12 ftw400 cl-info text-center mt8">我是全新的商户，不懂如何开通</div>
								<div class="flex center mt16">
									<a-button type="primary" @click="servicePayAct()">联系客服</a-button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div v-if="merchantSettingLoading">
				<merchant-setting :visible="merchantSettingLoading" @cancel="cancelMerchantSetting" @ok="okMerchantSetting"></merchant-setting>
			</div>
			<div v-if="servicePayLoading">
				<service-pay :visible="servicePayLoading" @cancel="cancelServicePay"></service-pay>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import merchantSetting from './components/payManage/merchantSetting.vue';
	import servicePay from './components/payManage/servicePay.vue';
	export default{
		components:{
			merchantSetting,
			servicePay,
		},
		data(){
			return{
				loading:false,
				is_setting:0,
				type:0,
				merchantSettingLoading:false,
				servicePayLoading:false,
			}
		},
		created() {
			this.loaddata();
		},
		methods:{
			
			loaddata(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.papi('platform/terminal/showPaymentStatus').then(res=>{
					this.is_setting=res.is_setting;
					this.type=res.type;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			merchantSettingAct(){
				this.merchantSettingLoading=true;
			},
			cancelMerchantSetting(){
				this.merchantSettingLoading=false;
			},
			okMerchantSetting(){
				this.merchantSettingLoading=false;
				this.loaddata();
			},
			
			servicePayAct(){
				this.servicePayLoading=true;
			},
			cancelServicePay(){
				this.servicePayLoading=false;
			},
		},
	}
</script>

<style>
	.app-pay-manage-item{
		width: 400px;
		height: 188px;
		background: #F7F8FC;
		border-radius: 8px;
		padding: 40px 0px 40px 0px;
	}
</style>
